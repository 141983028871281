import React from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  ButtonGroup,
  Typography,
  useMediaQuery,
  useScrollTrigger
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

import { sectionScrollOffset } from '../../app/config';
import NavDrawer from './NavDrawer';

// import logoImg from '../../assets/img/logo/logo.svg';

const StyledAppBar = styled(AppBar)({
  background: 'transparent',
  position: 'fixed',
  transition: 'all .5s',
  zIndex: '1001'
});

const StyledAppBarScrolled = styled(AppBar)({
  background: 'white',
  opacity: '0.95',
  color: 'black',
  position: 'fixed',
  transition: 'all .5s',
  zIndex: '1001'
});

const LogoButton = styled(Button)({
  textTransform: 'none',
  color: 'inherit',
  transition: 'all .3s',
  '&:hover': {
    backgroundColor: 'transparent',
    transform: 'scaleX(1.1) scaleY(1.2)'
  },
  '&:active': {
    transform: 'translateY(.1rem)'
  },
  '&:focus-visible': {
    transform: 'scaleX(1.1) scaleY(1.2)',
    outline: 'none !important'
  }
});

const MenuButton = styled(Button)({
  // marginLeft: theme.spacing(0.1),
  color: 'inherit',
  letterSpacing: '2px',
  textTransform: 'none',
  fontWeight: 600,
  border: 'none',
  outline: 'none',
  borderRadius: '0px',
  transition: 'all .3s',
  '&:hover': {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    transform: 'scaleX(1.1) scaleY(1.2)'
  },
  '&:active': {
    transform: 'translateY(.1rem)'
  },
  '&:focus-visible': {
    transform: 'scaleX(1.1) scaleY(1.2)'
  }
});

const MenuButtonSelected = styled(MenuButton)({
  borderBottom: '1px solid currentColor'
});

const config = {
  menuItems: ['Početna', 'O nama', 'Usluge', 'Naši projekti', 'Kontakt']
};

interface ElevationScrollProps {
  children: React.ReactElement;
}

const ElevationScroll = (props: ElevationScrollProps): React.ReactElement => {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  const elevation = trigger ? 4 : 0;

  return trigger ? (
    <StyledAppBarScrolled position="static" elevation={elevation}>
      {children}
    </StyledAppBarScrolled>
  ) : (
    <StyledAppBar position="static" elevation={elevation}>
      {children}
    </StyledAppBar>
  );
};

interface NavBarProps {
  navValue: number;
  setNavValue: React.Dispatch<React.SetStateAction<number>>;
  refs: React.RefObject<HTMLDivElement>[];
}

const NavBar = ({
  navValue,
  setNavValue,
  refs
}: NavBarProps): React.ReactElement => {
  const theme = useTheme();

  const matchesMD = useMediaQuery(theme.breakpoints.down('lg'));

  const logo = (
    <Typography
      variant="h6"
      style={{
        fontSize: '2.6rem',
        fontWeight: 600,
        textTransform: 'uppercase'
      }}
    >
      shema-e
    </Typography>
  );

  const logoButton = (
    <LogoButton
      disableRipple
      disableFocusRipple
      onClick={() => {
        window.scrollTo({
          // @ts-ignore: Object is possibly 'null'.
          top: refs[0]?.current?.offsetTop - sectionScrollOffset,
          behavior: 'smooth'
        });
      }}
    >
      {logo}
    </LogoButton>
  );

  const buttons = (
    <ButtonGroup
      component="div"
      disableRipple
      disableFocusRipple
      sx={{ marginLeft: 'auto' }}
    >
      {config.menuItems.map((item, index) => {
        return navValue === index ? (
          <MenuButtonSelected
            key={index}
            onClick={() => {
              window.scrollTo({
                // @ts-ignore: Object is possibly 'null'.
                top: refs[index]?.current?.offsetTop - sectionScrollOffset,
                behavior: 'smooth'
              });
            }}
          >
            <Typography variant="body2">{item}</Typography>
          </MenuButtonSelected>
        ) : (
          <MenuButton
            key={index}
            onClick={() => {
              window.scrollTo({
                // @ts-ignore: Object is possibly 'null'.
                top: refs[index]?.current?.offsetTop - sectionScrollOffset,
                behavior: 'smooth'
              });
            }}
          >
            <Typography variant="body2">{item}</Typography>
          </MenuButton>
        );
      })}
    </ButtonGroup>
  );

  const renderedDrawer = (
    <NavDrawer
      items={config.menuItems}
      refs={refs}
      logo={logo}
      navValue={navValue}
      setNavValue={setNavValue}
    />
  );

  return (
    <ElevationScroll>
      <Toolbar
        sx={(theme) => ({
          margin: 'auto',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          width: theme.custom.contentHeroWidth,
          [theme.breakpoints.down('lg')]: {
            width: '90%'
          }
        })}
      >
        {logoButton}
        {matchesMD ? renderedDrawer : buttons}
      </Toolbar>
    </ElevationScroll>
  );
};

export default NavBar;
