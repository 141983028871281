import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import HeroCarouselItem from './HeroCarouselItem';

import carousel1 from '../../assets/img/optimized/carousel-1.jpg';
import carousel1webp from '../../assets/img/optimized/carousel-1.webp';
import carousel3 from '../../assets/img/optimized/carousel-3.jpg';
import carousel3webp from '../../assets/img/optimized/carousel-3.webp';
import carousel6 from '../../assets/img/optimized/carousel-6.jpg';
import carousel6webp from '../../assets/img/optimized/carousel-6.webp';

const config = {
  items: [
    {
      title: 'Projektovanje električnih instalacija',
      backgroundImage: carousel1,
      backgroundImageWebp: carousel1webp
    },
    {
      title: 'Konsultantske usluge i nadzor radova',
      backgroundImage: carousel6,
      backgroundImageWebp: carousel6webp
    },
    {
      title: 'Izvođenje elektro-radova',
      backgroundImage: carousel3,
      backgroundImageWebp: carousel3webp
    }
  ]
};

const StyledCarousel = styled(Carousel)({
  height: '100%'
});

interface HeroCarouselProps {
  webp: boolean;
}

const HeroCarousel = ({ webp }: HeroCarouselProps): React.ReactElement => {
  const theme = useTheme();

  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

  // const servicesButton = (
  //   <Button
  //     variant="contained"
  //     style={{ textTransform: 'none', padding: '1rem 3rem' }}
  //   >
  //     <Typography variant="subtitle1">Pogledajte usluge</Typography>
  //   </Button>
  // );

  return (
    <StyledCarousel
      additionalTransfrom={0}
      arrows
      autoPlay={matchesMD ? false : true}
      autoPlaySpeed={5000}
      centerMode={false}
      containerClass="container"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024
          },
          items: 1
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0
          },
          items: 1
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464
          },
          items: 1
        }
      }}
      showDots
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      {config.items.map(
        (
          item: {
            title: string;
            backgroundImage: string;
            backgroundImageWebp: string;
          },
          index
        ) => (
          <HeroCarouselItem
            key={index}
            title={item.title}
            backgroundImage={item.backgroundImage}
            backgroundImageWebp={item.backgroundImageWebp}
            webp={webp}
          />
        )
      )}
    </StyledCarousel>
  );
};

export default HeroCarousel;
