import React, { useEffect, useRef, useState } from 'react';
import NavBar from './features/ui/NavBar';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';

import { Paper } from '@mui/material';

import SectionHero from './features/ui/SectionHero';
import SectionAbout from './features/ui/SectionAbout';
import SectionServices from './features/ui/SectionServices';
import SectionProjects from './features/ui/SectionProjects';
import SectionContact from './features/ui/SectionContact';
import Footer from './features/ui/Footer';
import NoMatch from './features/ui/NoMatch';

import ReactGA from 'react-ga';
import { gaTrackingId } from './app/googleAnalyticsConfig';
import { Helmet } from 'react-helmet';

function support_format_webp() {
  var elem = document.createElement('canvas');

  if (!!(elem.getContext && elem.getContext('2d'))) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  } else {
    // very old browser like IE 8, canvas not supported
    return false;
  }
}

const App = (): React.ReactElement => {
  const [navValue, setNavValue] = useState(0);

  const [webp, setWebp] = useState(false);

  useEffect(() => {
    setWebp(support_format_webp());
    ReactGA.initialize(gaTrackingId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [
    sectionHeroRef,
    sectionAboutRef,
    sectionServicesRef,
    sectionProjectsRef,
    sectionContactRef
  ] = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null)
  ];

  return (
    <React.Fragment>
      <CssBaseline />
      <Helmet>
        <title>Shema-E d.o.o.</title>
        <meta
          name="description"
          content="Projektovanje, izvođenje i nadzor elektro radova"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <meta
          name="keywords"
          content="projektovanje,
          projektovanje električnih instalacija,
          konsultantske usluge i nadzor radova,
          izvođenje elektro-radova,
          elektro,
          instalacije,
          instalacije jake struje,
          instalacije slabe struje,
          instalacije tt,
          uzemljenje,
          uzemljenje kuće,
          uzemljenje trafostanice,
          uzemljenje za kucu,
          gromobran,
          gromobranska traka,
          gromobranska instalacija,
          gromobran za kucu,
          gromobran i uzemljenje,
          gromobran na kuci,
          telekomunikacije,
          pametne,
          pametne kuce,
          pametne brave,
          knx,
          knx smart home,
          knx taster,
          knx bus,
          hotelski sistemi,
          hotelske instalacije,
          sistemi,
          nadzor,
          nadzorne kamere,
          nadzorni odbor,
          nadzorni inženjer,
          nadzor gradnje,
          nadzornik,
          radovi,
          shema,
          shema-e,
          shema električne instalacije stana,
          shema electric,
          izvođenje,
          izvođenje elektroinstalacija,
          sarajevo,
          visoko,
          hadžići,
          poslovni,
          kompleks,
          radova"
        ></meta>
        <meta name="author" content="Shema-E"></meta>
      </Helmet>
      <Paper square>
        <Router>
          <Switch>
            <Route exact path="/">
              <NavBar
                navValue={navValue}
                setNavValue={setNavValue}
                refs={[
                  sectionHeroRef,
                  sectionAboutRef,
                  sectionServicesRef,
                  sectionProjectsRef,
                  sectionContactRef
                ]}
              />
              <SectionHero
                ref={sectionHeroRef}
                setNavValue={setNavValue}
                webp={webp}
              />
              <SectionAbout ref={sectionAboutRef} setNavValue={setNavValue} />
              <SectionServices
                ref={sectionServicesRef}
                setNavValue={setNavValue}
              />
              <SectionProjects
                ref={sectionProjectsRef}
                setNavValue={setNavValue}
                webp={webp}
              />
              <SectionContact
                ref={sectionContactRef}
                setNavValue={setNavValue}
              />
            </Route>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Router>
        <Footer />
      </Paper>
    </React.Fragment>
  );
};

export default App;
