import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  useMediaQuery,
  useTheme,
  Typography,
  Grid,
  TextField,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';

import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Mail';
import HouseIcon from '@mui/icons-material/House';

import Green from '@mui/material/colors/green';

import MuiAlert, { AlertProps } from '@mui/lab/Alert';

import Section from './Section';

import emailjs from 'emailjs-com';
import { emailConfig } from '../../app/emailConfig';
import { useInView } from 'react-intersection-observer';
import { useInViewScroll } from '../../app/hooks';
import GoogleMapsLocation from './GoogleMapsLocation';

const Row = styled(Grid)(
  ({ matchesMD, inView }: { matchesMD: boolean; inView: boolean }) => ({
    transition: 'transform .8s, opacity .8s',
    transform: !matchesMD && !inView ? 'translateY(8rem)' : 'none',
    opacity: !matchesMD && !inView ? 0 : 1,
    '& .MuiFormHelperText-root': {
      width: '100%',
      margin: 0,
      paddingLeft: '1rem',
      backgroundColor: '#d8fdfd'
    }
  })
);

const Form = styled('form')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%'
  },
  '& .MuiTextField-root': {
    margin: 0,
    width: '100%'
  }
}));

const ButtonDone = styled(Button)({
  backgroundColor: Green[400],
  width: '100%',
  height: '5rem',
  '&:hover': {
    cursor: 'default',
    backgroundColor: Green[400]
  }
});

const Text = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}));

// const config = {
//   formFields: [
//     {
//       grid: {
//         md: 6 as GridSize,
//         xs: 12 as GridSize
//       },
//       fieldBos: {
//         name: 'firstName',
//         label: 'Ime',
//         errorMessage: 'Polje ne može biti prazno'
//       }
//     },
//     {
//       grid: {
//         md: 6 as GridSize,
//         xs: 12 as GridSize
//       },
//       fieldBos: {
//         name: 'lastName',
//         label: 'Prezime',
//         errorMessage: 'Polje ne može biti prazno'
//       }
//     },
//     {
//       grid: {
//         xs: 12 as GridSize
//       },
//       fieldBos: {
//         name: 'email',
//         label: 'E-mail',
//         errorMessage: 'Polje ne može biti prazno'
//       }
//     },
//     {
//       grid: {
//         xs: 12 as GridSize
//       },
//       fieldBos: {
//         name: 'subject',
//         label: 'Naslov',
//         errorMessage: 'Polje ne može biti prazno'
//       }
//     },
//     {
//       grid: {
//         xs: 12 as GridSize
//       },
//       fieldBos: {
//         name: 'message',
//         label: 'Poruka',
//         errorMessage: 'Polje ne može biti prazno',
//         multiline: true,
//         rows: 6
//       }
//     }
//   ]
// };

const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

interface SectionContactProps {
  setNavValue: React.Dispatch<React.SetStateAction<number>>;
}

const SectionContact = React.forwardRef(
  ({ setNavValue }: SectionContactProps, ref): React.ReactElement => {
    const theme = useTheme();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [sending, setSending] = useState(false);

    // Form inputs
    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [subject, setSubject] = useState('');
    const [subjectError, setSubjectError] = useState('');
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState('');

    // form validation error
    const [validationError, setValidationError] = useState(false);

    // Breadcrumb
    const [emailSuccess, setEmailSuccess] = useState<boolean | null>(null);
    const [, setErrorMessage] = useState('');

    const isVisibleSection = useInViewScroll(
      ref as React.RefObject<HTMLElement>,
      0.51,
      false
    );

    useEffect(() => {
      if (isVisibleSection === true) {
        setNavValue(4);
      }
    }, [isVisibleSection, setNavValue]);

    const { ref: refFade, inView } = useInView({
      threshold: 0.2,
      triggerOnce: true
    });

    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

    const onSnackbarClose = (
      event?: Event | SyntheticEvent,
      reason?: SnackbarCloseReason
    ) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpenSnackbar(false);
    };

    const sendEmail = (e: any) => {
      e.preventDefault();

      if (emailSuccess) return;

      let hasErrors = false;

      if (firstName === '') {
        setFirstNameError('Polje ne može biti prazno');
        hasErrors = true;
      }
      if (lastName === '') {
        setLastNameError('Polje ne može biti prazno');
        hasErrors = true;
      }
      if (!validateEmail(email)) {
        setEmailError('Neispravna e-mail adresa');
        hasErrors = true;
      }
      if (email === '') {
        setEmailError('Polje ne može biti prazno');
        hasErrors = true;
      }
      if (subject === '') {
        setSubjectError('Polje ne može biti prazno');
        hasErrors = true;
      }
      if (message === '') {
        setMessageError('Polje ne može biti prazno');
        hasErrors = true;
      }

      if (hasErrors) {
        setValidationError(true);
      } else {
        setSending(true);

        emailjs
          .sendForm(
            emailConfig.serviceId,
            emailConfig.templateId,
            e.target,
            emailConfig.userId
          )
          .then(
            (_) => {
              setEmailSuccess(true);
              setOpenSnackbar(true);
              setSending(false);
              e.target.reset();
            },
            (error) => {
              setEmailSuccess(false);
              setOpenSnackbar(true);
              setSending(false);
              setErrorMessage('');
            }
          );
      }
    };

    const Alert = (props: AlertProps) => {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    };

    return (
      <Section
        ref={ref as React.RefObject<HTMLElement>}
        id="sectionContact"
        title="kontakt"
        backgroundColor="#D8FDFD"
        bottomContent={<GoogleMapsLocation />}
      >
        <Row
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          ref={refFade}
          matchesMD={matchesMD}
          inView={inView}
          gap="3rem"
          xs={10}
          md={8}
          margin="auto"
        >
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            direction="column"
            gap="2rem"
          >
            <Grid item>
              <Typography variant="h3" align="center">
                Kontaktirajte nas
              </Typography>
            </Grid>
            <Grid item>
              <Text variant="body2" align="center">
                Za sva pitanja možete nam se obratiti preko forme ispod, ili
                preko navedenih kontakt podataka
              </Text>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={10}
            md={8}
            lg={6}
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Form onSubmit={sendEmail} noValidate>
              <Grid item container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="firstName"
                    label="Ime"
                    value={firstName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFirstName(e.target.value);
                      setFirstNameError('');
                      validationError && setValidationError(false);
                    }}
                    error={firstNameError === '' ? false : true}
                    helperText={
                      firstNameError === '' ? undefined : firstNameError
                    }
                    variant="outlined"
                    style={{ backgroundColor: 'white' }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="lastName"
                    label="Prezime"
                    value={lastName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setLastName(e.target.value);
                      setLastNameError('');
                      validationError && setValidationError(false);
                    }}
                    error={lastNameError === '' ? false : true}
                    helperText={
                      lastNameError === '' ? undefined : lastNameError
                    }
                    variant="outlined"
                    style={{ backgroundColor: 'white' }}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    label="E-mail adresa"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(e.target.value);
                      setEmailError('');
                      validationError && setValidationError(false);
                    }}
                    error={emailError === '' ? false : true}
                    helperText={emailError === '' ? undefined : emailError}
                    variant="outlined"
                    style={{ backgroundColor: 'white' }}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="subject"
                    label="Naslov"
                    value={subject}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSubject(e.target.value);
                      setSubjectError('');
                      validationError && setValidationError(false);
                    }}
                    error={subjectError === '' ? false : true}
                    helperText={subjectError === '' ? undefined : subjectError}
                    variant="outlined"
                    style={{ backgroundColor: 'white' }}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="message"
                    label="Poruka"
                    value={message}
                    multiline={true}
                    rows={6}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setMessage(e.target.value);
                      setMessageError('');
                      validationError && setValidationError(false);
                    }}
                    error={messageError === '' ? false : true}
                    helperText={messageError === '' ? undefined : messageError}
                    variant="outlined"
                    style={{ backgroundColor: 'white' }}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  {sending ? (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ width: '100%', height: '5rem' }}
                      disabled={true}
                    >
                      <CircularProgress />
                    </Button>
                  ) : emailSuccess ? (
                    <ButtonDone
                      type="submit"
                      variant="contained"
                      disableElevation
                      disableRipple
                      disableFocusRipple
                      endIcon={<CheckIcon />}
                    />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ width: '100%', height: '5rem' }}
                      endIcon={<SendIcon />}
                    >
                      Pošalji
                    </Button>
                  )}
                </Grid>
                {validationError ? (
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      Molimo da popravite unos u označenim poljima
                    </Alert>
                  </Grid>
                ) : null}
              </Grid>
            </Form>
          </Grid>
          <Grid
            item
            container
            xs={10}
            md={8}
            lg={6}
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
          >
            <Grid item container xs={12} md={6} alignItems="center">
              <Grid item>
                <PhoneIcon
                  style={{
                    fontSize: '3.5rem',
                    color: theme.palette.primary.main
                  }}
                />
              </Grid>
              <Grid item style={{ marginLeft: '1.5rem' }}>
                <Typography>+387 61 455-154</Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6} alignItems="center">
              <Grid item>
                <EmailIcon
                  style={{
                    fontSize: '3.5rem',
                    color: theme.palette.primary.main
                  }}
                />
              </Grid>
              <Grid item style={{ marginLeft: '1.5rem' }}>
                <Typography>info@shema-e.ba</Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <Grid item>
                <HouseIcon
                  style={{
                    fontSize: '3.5rem',
                    color: theme.palette.primary.main
                  }}
                />
              </Grid>
              <Grid item style={{ marginLeft: '1.5rem' }}>
                <Typography>Halilovići 10, Sarajevo</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            onClose={onSnackbarClose}
          >
            {emailSuccess ? (
              <Alert onClose={onSnackbarClose} severity="success">
                Poruka uspješno poslata
              </Alert>
            ) : (
              <Alert onClose={onSnackbarClose} severity="error">
                Greška prilikom slanja poruke. Molimo pokušajte kasnije.
              </Alert>
            )}
          </Snackbar>
        </Row>
      </Section>
    );
  }
);

export default SectionContact;
