import React, { useEffect } from 'react';
import { Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useInView } from 'react-intersection-observer';

import Section from './Section';

import bulb from '../../assets/img/bulb.svg';
import security from '../../assets/img/security.svg';
import electro from '../../assets/img/electro.svg';
import smart from '../../assets/img/smart.svg';
import network from '../../assets/img/network.svg';
import oversight from '../../assets/img/oversight.svg';
import { useInViewScroll } from '../../app/hooks';

const Row = styled(Grid)(
  ({ matchesMD, inView }: { matchesMD: boolean; inView: boolean }) => ({
    transition: 'transform .8s, opacity .8s',
    transform: !matchesMD && !inView ? 'translateY(8rem)' : 'none',
    opacity: !matchesMD && !inView ? 0 : 1
  })
);

const Icon = styled('img')({ width: '18rem' });

interface SectionServicesProps {
  setNavValue: React.Dispatch<React.SetStateAction<number>>;
}

const SectionServices = React.forwardRef(
  ({ setNavValue }: SectionServicesProps, ref): React.ReactElement => {
    const theme = useTheme();

    const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

    const isVisibleSection = useInViewScroll(
      ref as React.RefObject<HTMLElement>,
      0.51,
      false
    );

    useEffect(() => {
      if (isVisibleSection === true) {
        setNavValue(2);
      }
    }, [isVisibleSection, setNavValue]);

    const { ref: refFade, inView } = useInView({
      threshold: 0.2,
      triggerOnce: true
    });

    const config = {
      items: [
        {
          title: 'Instalacije jake struje',
          description: null,
          // description:
          //   'Projektovanje i ugradnja instalacija jake i slabe struje',
          image: bulb,
          alt: 'Icon with a electricity symbol'
        },
        {
          title: 'Instalacije uzemljenja i gromobrana',
          description: null,
          // description:
          //   'Projektovanje i ugradnja instalacija rasvjete uz preciznu procjenu gabarita',
          image: electro,
          alt: 'Icon with a lightbulb'
        },
        {
          title: 'Telekomunikacione instalacije',
          description: null,
          // description:
          //   'Projektovanje i ugradnja telekomunikacijske i KNX opreme najnovije generacije',
          image: network,
          alt: 'Icon with a network planet symbol'
        },
        {
          title: 'Pametne instalacije (hotelski sistemi i KNX instalacije)',
          description: null,
          // description:
          //   'Projektovanje i ugradnja pametnih instalacija, te kompletnih rješenja za pametne kuće',
          image: smart,
          alt: 'Icon of a control screen'
        },
        {
          title: 'Sigurnosni sistemi',
          description: null,
          // description: 'Projektovanje i ugradnja sigurnosnih sistema',
          image: security,
          alt: 'Icon with a padlock'
        },
        {
          title: 'Nadzor elektro radova',
          description: null,
          // description:
          //   'Nudimo i konsultantske usluge, te usluge nadzora radova',
          image: oversight,
          alt: 'Icon of a man holding a folder'
        }
      ]
    };

    return (
      <Section
        ref={ref}
        id="sectionServices"
        title="usluge"
        backgroundColor="#D8FDFD"
      >
        <Row
          item
          container
          ref={refFade}
          xs={12}
          justifyContent="center"
          alignItems="center"
          matchesMD={matchesMD}
          inView={inView}
          gap={matchesMD ? 4 : 0}
        >
          {config.items.map((item, index) => {
            return (
              <Grid
                key={index}
                item
                container
                xs={12}
                lg={4}
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                style={{ height: matchesLG ? 'auto' : '30rem' }}
              >
                <Grid item>
                  <Icon src={item.image} alt={item.alt} />
                </Grid>
                <Grid item container direction="column">
                  <Grid item>
                    <Typography variant="h4" align="center">
                      {item.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" align="center">
                      {item.description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Row>
      </Section>
    );
  }
);

export default SectionServices;
