import React, { useState } from 'react';
import {
  Grid,
  List,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  IconButton
} from '@mui/material';

import Menu from '@mui/icons-material/Menu';

import { sectionScrollOffset } from '../../app/config';

interface DrawerProps {
  items: string[];
  refs: (React.RefObject<HTMLDivElement> | React.MutableRefObject<undefined>)[];
  logo: JSX.Element;
  navValue: number;
  setNavValue: React.Dispatch<React.SetStateAction<number>>;
}

const NavDrawer = ({
  items,
  refs,
  logo,
  navValue,
  setNavValue
}: DrawerProps): React.ReactElement => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const list = (
    <List disablePadding dense>
      <Grid
        container
        sx={(theme) => ({
          height: '6rem',
          width: '35rem',
          marginLeft: '1.5rem',
          [theme.breakpoints.down('sm')]: {
            width: '25rem'
          }
        })}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item>{logo}</Grid>
      </Grid>
      {items.map((item, index) => {
        return (
          <ListItemButton
            key={index}
            onClick={() => {
              setOpenDrawer(false);
              setNavValue(index);
              window.scrollTo({
                // @ts-ignore: Object is possibly 'null'.
                top: refs[index]?.current?.offsetTop - sectionScrollOffset,
                behavior: 'smooth'
              });
              // refs[index].current?.scrollIntoView({
              //   behavior: 'smooth'
              // });
            }}
            divider
            selected={navValue === index}
          >
            <ListItemText
              style={{
                opacity: navValue === index ? 1 : '0.7',
                width: '15rem'
              }}
              disableTypography
            >
              {item}
            </ListItemText>
          </ListItemButton>
        );
      })}
    </List>
  );

  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={!iOS}
        open={openDrawer}
        onOpen={() => setOpenDrawer(true)}
        onClose={() => setOpenDrawer(false)}
        // variant="persistent"
      >
        {list}
      </SwipeableDrawer>

      <IconButton
        style={{
          color: 'inherit',
          height: '3rem',
          width: '3rem',
          padding: '2.5rem',
          marginLeft: 'auto'
        }}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        <Menu style={{ fontSize: '4rem' }} />
      </IconButton>
    </React.Fragment>
  );

  return drawer;
};

export default NavDrawer;
