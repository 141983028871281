import React, { useEffect } from 'react';
import {
  Typography,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
  Theme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { useInView } from 'react-intersection-observer';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Section from './Section';
import CustomCard from './CustomCard';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LensIcon from '@mui/icons-material/Lens';

import facetv from '../../assets/img/optimized/facetv.jpg';
import facetvWebp from '../../assets/img/optimized/facetv.webp';
import bosmal from '../../assets/img/optimized/bosmal.jpg';
import bosmalWebp from '../../assets/img/optimized/bosmal.webp';
import ius from '../../assets/img/optimized/ius.jpg';
import iusWebp from '../../assets/img/optimized/ius.webp';
import hifaoil from '../../assets/img/optimized/hifaoil.jpg';
import hifaoilWebp from '../../assets/img/optimized/hifaoil.webp';
import osenik from '../../assets/img/optimized/osenik.jpg';
import osenikWebp from '../../assets/img/optimized/osenik.webp';
import visokomedresa from '../../assets/img/optimized/visokomedresa.jpg';
import visokomedresaWebp from '../../assets/img/optimized/visokomedresa.webp';
import vizijavisoko from '../../assets/img/optimized/vizijavisoko.jpg';
import vizijavisokoWebp from '../../assets/img/optimized/vizijavisoko.webp';
import rawasi from '../../assets/img/optimized/rawasi.jpg';
import rawasiWebp from '../../assets/img/optimized/rawasi.webp';
import opcinavisoko from '../../assets/img/optimized/opcinavisoko.jpg';
import opcinavisokoWebp from '../../assets/img/optimized/opcinavisoko.webp';
import izvorsarajevo from '../../assets/img/optimized/izvorsarajevo.jpg';
import izvorsarajevoWebp from '../../assets/img/optimized/izvorsarajevo.webp';
import vemavisoko from '../../assets/img/optimized/vemavisoko.jpg';
import vemavisokoWebp from '../../assets/img/optimized/vemavisoko.webp';
import mili from '../../assets/img/optimized/mili.jpg';
import miliWebp from '../../assets/img/optimized/mili.webp';
import diamedic from '../../assets/img/optimized/diamedic.jpg';
import diamedicWebp from '../../assets/img/optimized/diamedic.webp';
import edeka from '../../assets/img/optimized/edeka.jpg';
import edekaWebp from '../../assets/img/optimized/edeka.webp';

import { useInViewScroll } from '../../app/hooks';

const StyledCarousel = styled(Carousel)({ height: '32rem', width: '90%' });

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    carousel: {
      height: '32rem'
    }
  })
);

const Row = styled(Grid)(
  ({ matchesMD, inView }: { matchesMD: boolean; inView: boolean }) => ({
    transition: 'transform .8s, opacity .8s',
    transform: !matchesMD && !inView ? 'translateY(8rem)' : 'none',
    opacity: !matchesMD && !inView ? 0 : 1
  })
);

const config = {
  cards: [
    {
      title: 'Internacionalni Univerzitet u Sarajevu',
      description: '',
      image: ius,
      imageWebp: iusWebp
    },
    {
      title: 'Bosmal City Center',
      description: '',
      image: bosmal,
      imageWebp: bosmalWebp
    },
    {
      title: 'Hifa Oil benzinske pumpe',
      description: '',
      image: hifaoil,
      imageWebp: hifaoilWebp
    },
    {
      title: 'Naselje Osenik, Hadžići',
      description: '',
      image: osenik,
      imageWebp: osenikWebp
    },
    {
      title: 'Medresa Osman-ef. Redžović, Visoko',
      description: '',
      image: visokomedresa,
      imageWebp: visokomedresaWebp
    },
    {
      title: 'Poslovni centar "Vizija", Visoko',
      description: '',
      image: vizijavisoko,
      imageWebp: vizijavisokoWebp
    },
    {
      title: 'Country Side Resort | Rawasi, Hadžići',
      description: '',
      image: rawasi,
      imageWebp: rawasiWebp
    },
    {
      title: 'Zgrada opštine Visoko',
      description: '',
      image: opcinavisoko,
      imageWebp: opcinavisokoWebp
    },
    {
      title: 'Stambeno-poslovni kompleks "Izvor", Sarajevo',
      description: '',
      image: izvorsarajevo,
      imageWebp: izvorsarajevoWebp
    },
    {
      title: 'Poslovni centar "Vema", Visoko',
      description: '',
      image: vemavisoko,
      imageWebp: vemavisokoWebp
    },
    {
      title: 'Arheološki lokalitet "Mili", Visoko',
      description: '',
      image: mili,
      imageWebp: miliWebp
    },
    {
      title: 'Diamedic, Sarajevo',
      description: '',
      image: diamedic,
      imageWebp: diamedicWebp
    },
    {
      title: 'Kompleks "Edeka", Podlugovi',
      description: '',
      image: edeka,
      imageWebp: edekaWebp
    },
    {
      title: 'Face TV',
      description: '',
      image: facetv,
      imageWebp: facetvWebp
    }
  ],
  otherProjects: [
    'Apoteka Osijek, Ilidža',
    'Benzinska pumpa i pomoćni objekti "Suša", Donje Moštre',
    '"ABS" banka (Sparkasse bank), više poslovnica u BiH',
    'Đački dom "Veliko Čajno", Visoko ( Muški i ženski internat )',
    '“Eurobest“, Kakanj',
    'Nadbiskupijski centar za pastoral mladih Ivan Pavao II, Sarajevo',
    'Benzinska pumpa  “Hamsel”, Vogošća',
    '“Keksara” Visoko',
    'Vanjsko uređenje (vanjska rasvjeta i TK mreža), naselje Lokve, Hadžići',
    'Studentski dom Ilidža',
    '"Vita life”, Visoko',
    'Vanjsko uređenje (vanjska rasvjeta i TK mreža), naselje Mratnjevača, Sarajevo',
    'Kompleks stambenih objekata u naselju Mratnjevača, Sarajevo',
    'Prerada mesa "Semko", Visoko',
    'Samouslužne autopraonice, Nedžarići, Sarajevo',
    'Vanjsko uređenje (vanjska rasvjeta i TK mreža), idejni i glavni projekat, Podgaj-Tekija, Sarajevo',
    'Stambeni objekti, hotel i benzinska pumpa Ahmetspahić, Podgaj-Tekija, Sarajevo,',
    'Industrijski objekti, proizvodne hale u Sarajevu, Investitor Uniklima i Alternativa',
    'Vanjska rasvjeta pojedinih ulica u Visokom i platoa, trase i starog grada Visoko',
    'Benzinska pumpa Tranzitexport, Ilijaš',
    'Vanjska rasvjeta i iluminacija objekta Federalni zavod za poljoprivredu, Sarajevo',
    'Stambeno-poslovni kompleks „Izvor“, Sarajevo',
    'Poslovni objektat Agić, Industrijska zona Ozrakovići',
    'Hacienda de la Vida - Farma Kiseljak',
    'NLB banka BiH, više poslovnica u BiH',
    'Dom za starije osobe Veliko Čajno, Visoko',
    'Tržni centar KORT, Kiseljak',
    'Stambeno-poslovni objekat "Suša commerce", Visoko',
    'Rezidencija kardinala, Sarajevo'
  ]
};

interface SectionProjectsProps {
  setNavValue: React.Dispatch<React.SetStateAction<number>>;
  webp: boolean;
}

const SectionProjects = React.forwardRef(
  ({ setNavValue, webp }: SectionProjectsProps, ref): React.ReactElement => {
    const classes = useStyles();
    const theme = useTheme();

    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

    const isVisibleSection = useInViewScroll(
      ref as React.RefObject<HTMLElement>,
      0.51,
      false
    );

    useEffect(() => {
      if (isVisibleSection === true) {
        setNavValue(3);
      }
    }, [isVisibleSection, setNavValue]);

    const { ref: refFade1, inView: inView1 } = useInView({
      threshold: 0,
      triggerOnce: true
    });

    const { ref: refFade2, inView: inView2 } = useInView({
      threshold: 0,
      triggerOnce: true
    });

    return (
      <Section ref={ref} id="sectionProjects" title="Naši projekti">
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
          direction="column"
          gap="4rem"
        >
          {/* <Grid
          item
          ref={ref}
          className={
            !matchesMD
              ? inView
                ? classes.row
                : `${classes.row} ${classes.row}--invisible`
              : classes.row
          }
        >
          <Typography variant="h3" align="center">
            Naši projekti
          </Typography>
        </Grid> */}
          <Row item ref={refFade1} matchesMD={matchesMD} inView={inView1}>
            <Typography variant="h3" align="center">
              Istaknuto
            </Typography>
          </Row>
          <Row
            id="carouselProjectsContainer"
            ref={refFade1}
            matchesMD={matchesMD}
            inView={inView1}
            item
            container
            justifyContent="center"
            alignItems="center"
          >
            <StyledCarousel
              arrows
              autoPlaySpeed={3000}
              centerMode={false}
              containerClass="container"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={true}
              itemClass={classes.carousel}
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024
                  },
                  items: 4,
                  partialVisibilityGutter: 40
                },
                tablet: {
                  breakpoint: {
                    max: 1200,
                    min: 464
                  },
                  items: 3,
                  partialVisibilityGutter: 30
                },
                smallTablet: {
                  breakpoint: {
                    max: 1000,
                    min: 0
                  },
                  items: 2,
                  partialVisibilityGutter: 30
                },
                mobile: {
                  breakpoint: {
                    max: 600,
                    min: 0
                  },
                  items: 1,
                  partialVisibilityGutter: 30
                }
              }}
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {config.cards.map((card, index) => (
                <CustomCard
                  key={index}
                  title={card.title}
                  description={card.description}
                  image={webp ? card.imageWebp : card.image}
                  style={{
                    height: '29rem',
                    width: '95%',
                    marginTop: '1rem',
                    marginLeft: '.5rem',
                    padding: '2rem',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                  }}
                />
              ))}
            </StyledCarousel>
          </Row>
          <Row item ref={refFade2} matchesMD inView={inView2}>
            <Typography variant="h3" align="center">
              Još neki od objekata na kojima smo bili angažovani
            </Typography>
          </Row>
          <Row
            item
            container
            ref={refFade2}
            matchesMD
            inView={inView2}
            justifyContent="center"
            alignItems="center"
            xs={12}
          >
            <Grid item xs={12} md={11}>
              <Accordion
                square
                style={{
                  width: '100%',
                  boxShadow:
                    'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{
                    // paddingLeft: '3.1rem',
                    backgroundColor: 'rgba(0, 0, 0, .05)'
                  }}
                >
                  <Typography variant="h5">Objekti</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '0' }}>
                  {/* <Grid container direction="column" alignItems="flex-start">
                  {config.otherProjects.map((item, index) => {
                    return (
                      <Grid key={index} item>
                        <Typography variant="body1">{item}</Typography>
                      </Grid>
                    );
                  })}
                </Grid> */}
                  <List>
                    {config.otherProjects.map((item, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <LensIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary={item} />
                        </ListItem>
                      );
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Row>
          {/* <Grid item>
          <Typography variant="h3" align="center">
            Ostali projekti
          </Typography>
        </Grid> */}
          {/* <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <Carousel
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className={classes.carousel}
            containerClass="container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass={classes.carousel}
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024
                },
                items: 4,
                partialVisibilityGutter: 40
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0
                },
                items: 1,
                partialVisibilityGutter: 30
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464
                },
                items: 2,
                partialVisibilityGutter: 30
              }
            }}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {config.otherProjects.map((project, index) => {
              return (
                <Card
                  key={index}
                  style={{
                    height: '16rem',
                    width: '95%',
                    marginTop: '1rem',
                    marginLeft: '.5rem',
                    padding: '2rem',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                  }}
                >
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '100%', width: '100%' }}
                  >
                    <Typography variant="body2">{project}</Typography>
                  </Grid>
                </Card>
              );
            })}
          </Carousel>
        </Grid> */}
        </Grid>
      </Section>
    );
  }
);

export default SectionProjects;
