import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledFooter = styled('footer')({
  height: '15rem',
  backgroundColor: '#2D293D',
  color: 'white'
});

const Footer = (): React.ReactElement => {
  return (
    <StyledFooter>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ width: '100%', height: '100%' }}
      >
        <Grid item>
          <Typography
            variant="h6"
            style={{
              fontSize: '2.6rem',
              fontWeight: 600,
              textTransform: 'uppercase'
            }}
          >
            &copy; shema-e
          </Typography>
        </Grid>
      </Grid>
    </StyledFooter>
  );
};

export default Footer;
