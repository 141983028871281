import React from 'react';
import { styled } from '@mui/material/styles';

const StyledIframe = styled('iframe')({
  height: '600px',
  width: '100%',
  marginBlockEnd: '-3rem'
});

const GoogleMapsLocation = (): React.ReactElement => {
  return (
    <StyledIframe
      title="Shema-E d.o.o."
      style={{
        height: '600px',
        width: '100%'
      }}
      src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=(Shema-E%20d.o.o.)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
    />
  );
};

export default GoogleMapsLocation;
