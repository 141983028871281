import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';

import HeroCarousel from './HeroCarousel';

import { useInViewScroll } from '../../app/hooks';

const StyledSectionHero = styled('section')(({ theme }) => ({
  position: 'relative',
  height: '100vh',
  [theme.breakpoints.down('md')]: {
    height: 'auto'
  }
}));

// const useStyles = makeStyles((theme) =>
//   createStyles({
//     midStripTransparent: {
//       position: 'absolute',
//       padding: '3rem 0',
//       width: '100%',
//       top: '50%',
//       transform: 'translateY(-50%)',
//       backgroundColor: 'transparent',
//       '& > div': {
//         width: theme.custom.contentHeroWidth
//       }
//     }
//   })
// );

interface SectionHeroProps {
  setNavValue: React.Dispatch<React.SetStateAction<number>>;
  webp: boolean;
}

const SectionHero = React.forwardRef(
  ({ setNavValue, webp }: SectionHeroProps, ref): React.ReactElement => {
    const isVisibleSection = useInViewScroll(
      ref as React.RefObject<HTMLElement>,
      0.51,
      false
    );

    useEffect(() => {
      if (isVisibleSection === true) {
        setNavValue(0);
      }
    }, [isVisibleSection, setNavValue]);

    // const servicesButton = (
    //   <Button
    //     variant="contained"
    //     style={{ textTransform: 'none', padding: '1rem 3rem' }}
    //   >
    //     <Typography variant="subtitle1">Pogledajte usluge</Typography>
    //   </Button>
    // );

    return (
      <StyledSectionHero
        ref={ref as React.RefObject<HTMLElement>}
        id="sectionHero"
      >
        <HeroCarousel webp={webp} />
        {/* <Grid
        container
        className={classes.midStripTransparent}
        justifyContent="center"
      >
        <Grid
          item
          container
          justifyContent={matchesMD ? 'center' : 'flex-start'}
          spacing={3}
        >
          <Grid item lg={6} md={12}>
            <Typography
              variant="h1"
              style={{ color: 'transparent' }}
              align={matchesMD ? 'center' : 'left'}
            >
              Projektovanje i ugradnja električnih instalacija
            </Typography>
          </Grid>
          <Grid
            item
            container
            lg={6}
            md={12}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item></Grid>
          </Grid>
        </Grid>
      </Grid> */}
      </StyledSectionHero>
    );
  }
);

export default SectionHero;
