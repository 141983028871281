import React, { useEffect } from 'react';
import { Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import Section from './Section';

import experience from '../../assets/img/experience.svg';
import projects from '../../assets/img/projects.svg';
import happyusers from '../../assets/img/happyusers.svg';

import { useInView } from 'react-intersection-observer';
import { useInViewScroll } from '../../app/hooks';

import CountUp from 'react-countup';

import facetv from '../../assets/img/optimized/facetv.jpg';
import bosmal from '../../assets/img/optimized/bosmal.jpg';
import ius from '../../assets/img/optimized/ius.jpg';

const config = {
  items: [
    {
      image: experience,
      number: 20,
      text: 'godina iskustva',
      alt: 'icon with a hourglass'
    },
    {
      image: projects,
      number: 100,
      text: 'kompletiranih projekata',
      alt: 'icon of a list on a piece of paper'
    },
    {
      image: happyusers,
      number: 1000000,
      text: 'projektovanih m2',
      alt: 'icon of a trophy'
    }
  ],
  cards: [
    {
      title: 'Face TV',
      description: '',
      image: facetv
    },
    {
      title: 'Bosmal City Center',
      description: '',
      image: bosmal
    },
    {
      title: 'Internacionalni Univerzitet u Sarajevu',
      description: '',
      image: ius
    }
  ]
};

interface SectionAboutProps {
  setNavValue: React.Dispatch<React.SetStateAction<number>>;
}

const SectionAbout = React.forwardRef(
  ({ setNavValue }: SectionAboutProps, ref): React.ReactElement => {
    const theme = useTheme();

    const isVisibleSection = useInViewScroll(
      ref as React.RefObject<HTMLElement>,
      0.51,
      false
    );

    useEffect(() => {
      if (isVisibleSection === true) {
        setNavValue(1);
      }
    }, [isVisibleSection, setNavValue]);

    const { ref: ref1, inView } = useInView({
      threshold: 0.3,
      triggerOnce: true
    });

    const { ref: ref2, inView: inView2 } = useInView({
      threshold: 0.3,
      triggerOnce: true
    });

    const { ref: ref3, inView: inView3 } = useInView({
      threshold: 0.3,
      triggerOnce: true
    });

    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const matches1800 = useMediaQuery('(max-width:1800px)');
    return (
      <Section ref={ref} id="sectionAbout" title="O nama">
        <Grid container justifyContent="center">
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            direction="row"
          >
            <Grid
              item
              ref={ref1}
              xs={12}
              style={{
                transition: 'transform .8s, opacity .8s',
                transform: !matchesMD && !inView ? 'translateY(8rem)' : 'none',
                opacity: !matchesMD && !inView ? 0 : 1
              }}
              marginBottom="4rem"
            >
              <Typography variant="h3" align="center">
                Ko smo mi
              </Typography>
            </Grid>
            <Grid
              item
              ref={ref2}
              style={{
                transition: 'transform .8s, opacity .8s',
                transform: !matchesMD && !inView2 ? 'translateY(8rem)' : 'none',
                opacity: !matchesMD && !inView2 ? 0 : 1
              }}
              marginBottom="4rem"
            >
              <Typography
                variant="body2"
                align="center"
                style={{
                  margin: 'auto',
                  width: '60%',
                  [theme.breakpoints.down('md')]: {
                    width: '100%'
                  }
                }}
              >
                Shema-E je firma sa dugogodišnjim iskustvom u oblasti
                elektroenergetike. Predani smo pružanju najkvalitetnijih usluga
                po najvišim svjetskim standardima, kako za nove objekte u
                izgradnji, tako i za objekte u obnovi.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            ref={ref3}
            style={{
              transition: 'transform .8s, opacity .8s',
              transform: !matchesMD && !inView3 ? 'translateY(8rem)' : 'none',
              opacity: !matchesMD && !inView3 ? 0 : 1
            }}
            item
            container
            xs={12}
            justifyContent="space-betweenr"
            alignItems="center"
            gap={matchesMD ? '3.5rem' : 0}
          >
            {config.items.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  container
                  direction="column"
                  md={4}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <img
                      style={{ width: '17rem' }}
                      src={item.image}
                      alt={item.alt}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    style={{ width: 'auto' }}
                  >
                    <Grid item>
                      <Typography variant="subtitle1" align="center">
                        {!matchesMD ? (
                          inView3 ? (
                            <CountUp end={item.number} duration={3} />
                          ) : (
                            0
                          )
                        ) : (
                          item.number
                        )}
                        +
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        align={matches1800 ? 'center' : 'left'}
                      >
                        {item.text}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Section>
    );
  }
);

export default SectionAbout;
