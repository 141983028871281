import React from 'react';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      contentHeroWidth: React.CSSProperties['width'];
      contentSectionWidth: React.CSSProperties['width'];
      sectionPadding: React.CSSProperties['padding'];
    };
  }

  interface ThemeOptions {
    custom?: {
      contentHeroWidth: React.CSSProperties['width'];
      contentSectionWidth: React.CSSProperties['width'];
      sectionPadding: React.CSSProperties['padding'];
    };
  }
}

const theme = createTheme({
  typography: {
    htmlFontSize: 10,
    fontFamily: 'poppins, sans-serif',
    h1: {
      letterSpacing: '.7rem',
      fontSize: '3.5rem',
      textTransform: 'none'
    },
    h2: {
      color: '#067474',
      fontSize: '1.9rem',
      fontWeight: 'bold',
      letterSpacing: '.5rem',
      textTransform: 'uppercase'
    },
    h3: {
      fontSize: '3rem',
      fontWeight: 900,
      letterSpacing: '.5rem'
    },
    h4: {
      fontSize: '1.6rem',
      fontWeight: 600,
      letterSpacing: '.3rem'
    },
    subtitle1: {
      color: '#067474',
      fontSize: '3rem',
      fontWeight: 'bolder',
      letterSpacing: '.3rem',
      textTransform: 'uppercase'
    },
    subtitle2: {
      color: '#067474',
      fontSize: '1.4rem',
      fontWeight: 'bold',
      letterSpacing: '.3rem'
    },
    body1: {
      fontSize: '2rem'
    },
    body2: {
      fontSize: '1.5rem'
    }
  },
  custom: {
    contentHeroWidth: '60%',
    contentSectionWidth: '80%',
    sectionPadding: '3rem 0'
  }
});

export default responsiveFontSizes(theme);
