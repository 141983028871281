import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid, Button } from '@mui/material';
import Section from './Section';

const NoMatch = (): React.ReactElement => {
  return (
    <Section id="sectionError" title="Greška">
      <Grid
        style={{ minHeight: '300px', height: '66vh' }}
        container
        direction="column"
        alignItems="center"
      >
        <Grid item>
          <Typography style={{ fontSize: '8rem' }} variant="h1" align="center">
            404
          </Typography>
        </Grid>
        <Grid item style={{ paddingLeft: '5rem', paddingRight: '5rem' }}>
          <Typography variant="h5" align="center">
            Ova stranica ne postoji. Kliknite na dugme ispod da se vratite na
            početnu stranicu.
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          style={{ marginTop: '5rem' }}
        >
          <Button variant="contained" color="primary" component={Link} to="/">
            Nazad na početnu
          </Button>
        </Grid>
      </Grid>
    </Section>
  );
};

export default NoMatch;
