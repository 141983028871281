import React from 'react';
import {
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';

const CarouselItem = styled('div')({
  height: '100vh',
  width: '100vw',
  position: 'relative',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center'
});

const MidStrip = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  position: 'absolute',
  padding: '3rem 0',
  width: '100%',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(0,0,0, .2)',
  '& > div': {
    width: theme.custom.contentHeroWidth
  }
}));

interface HeroCarouselProps {
  title: string;
  backgroundImage: string;
  backgroundImageWebp: string;
  webp: boolean;
}

const HeroCarouselItem = ({
  title,
  backgroundImage,
  backgroundImageWebp,
  webp
}: HeroCarouselProps): React.ReactElement => {
  const theme = useTheme();

  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <CarouselItem
      style={{
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${
          webp ? backgroundImageWebp : backgroundImage
        })`
      }}
    >
      <MidStrip container justifyContent="center">
        <Container maxWidth="lg">
          <Grid item container justifyContent="flex-start">
            <Grid item xl={6} md={12} lg={10}>
              <Typography
                variant="h1"
                sx={{ color: 'white', width: '100%' }}
                align={matchesMD ? 'center' : 'left'}
              >
                {title}
              </Typography>
            </Grid>
            {/* <Grid
            item
            container
            lg={6}
            md={12}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <div style={{ height: '3.5rem' }}></div>
            </Grid>
          </Grid> */}
          </Grid>
        </Container>
      </MidStrip>
    </CarouselItem>
  );
};

export default HeroCarouselItem;
