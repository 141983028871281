import { useEffect, useState } from 'react';

export const useInViewScroll = (
  ref: React.RefObject<HTMLElement>,
  threshold: number,
  onlyOnce: boolean = false
) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const elHeight = ref?.current?.getBoundingClientRect().height as number;

  useEffect(() => {
    let th = threshold;

    if (elHeight > window.innerHeight * 1.7) {
      th = ((window.innerHeight * threshold) / elHeight) * threshold;
    }

    const onIntersection = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        setIntersecting(true);
      } else {
        if (onlyOnce === false) setIntersecting(false);
      }

      return;
    };

    const intersectionOptions = {
      root: null,
      threshold: th
    };

    const observer = new IntersectionObserver(
      onIntersection,
      intersectionOptions
    );

    observer.observe(ref.current as Element);

    if (onlyOnce === true) {
      return () => {
        observer.disconnect();
      };
    }
  }, [isIntersecting, ref, threshold, onlyOnce, elHeight]);

  return isIntersecting;
};
