import React from 'react';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)({
  height: '24rem',
  boxShadow:
    'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'
});

const StyledCardMedia = styled(CardMedia)({
  height: '15rem'
});

interface CustomCardProps {
  title: string;
  description: string;
  image: string;
  style?: React.CSSProperties;
}

const CustomCard = ({
  title,
  description,
  image,
  style
}: CustomCardProps): React.ReactElement => {
  return (
    <StyledCard style={style}>
      <StyledCardMedia image={image} title="Contemplative Reptile" />
      <CardContent style={{ paddingLeft: '0', paddingRight: '0' }}>
        <Typography variant="h6">{title}</Typography>
        {/* <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography> */}
      </CardContent>
    </StyledCard>
  );
};

export default CustomCard;
