import React from 'react';
import {
  Typography,
  Grid,
  Container,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useInView } from 'react-intersection-observer';

const SectionStyled = styled('section')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.custom.sectionPadding
}));

const ContainerStyled = styled(Container)(({ theme }) => ({
  padding: theme.custom.sectionPadding
}));

const Separator = styled('div')(({ visible }: { visible: boolean }) => ({
  height: '.5rem',
  backgroundColor: '#C8B4E4',
  transition: 'width .8s',
  backfaceVisibility: 'hidden',
  width: visible ? '6rem' : '1rem'
}));

const SeparatorMobile = styled('div')({
  height: '.5rem',
  backgroundColor: '#C8B4E4',
  backfaceVisibility: 'hidden',
  width: '6rem'
});

interface SectionProps {
  id: string;
  title: string;
  children: JSX.Element;
  backgroundColor?: React.CSSProperties['backgroundColor'];
  refEl?: (node?: Element | null | undefined) => void;
  bottomContent?: React.ReactNode;
}

const Section = React.forwardRef(
  (
    { id, title, children, backgroundColor, bottomContent }: SectionProps,
    ref
  ): React.ReactElement => {
    const theme = useTheme();

    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

    const { ref: refFade, inView } = useInView({
      threshold: 0,
      triggerOnce: true
    });

    return (
      <SectionStyled
        id={id}
        ref={ref as React.RefObject<HTMLElement>}
        style={{
          backgroundColor: backgroundColor ? backgroundColor : 'inherit',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <ContainerStyled maxWidth="lg">
          <Grid
            container
            justifyContent="center"
            marginBottom="32px"
            style={{ gap: '4rem' }}
          >
            <Typography variant="h2">{title}</Typography>
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
              marginBottom={'1rem'}
            >
              {matchesMD ? (
                <SeparatorMobile />
              ) : (
                <Separator ref={refFade} visible={inView} />
              )}
            </Grid>
          </Grid>
          {children}
        </ContainerStyled>
        {bottomContent}
      </SectionStyled>
    );
  }
);

export default Section;
